$white: #ffffff;
$black: #000000;
$light-gray: #fcfcfc;
$orange: #ff9d09;
$light-orange: #faaf3a;
$light-blue: #134c85;
$blue: #0e153e;
$dark-blue: #0a1134;
$light-purple: #bcc5ee;
$purple: #3e466e;

h1 {
  font-size: 64px !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: normal !important;
}
.h1-small {
  font-size: 48px !important;
}
h2 {
  font-size: 48px !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: normal !important;
}
.h2-small,
.h3-large {
  font-size: 36px !important;
}
h3 {
  font-size: 24px !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: normal !important;
}
p,
li {
  font-family: "Lato", sans-serif !important;
  font-weight: 300;
  font-size: 18px !important;
}
a {
  font-family: "Lato", sans-serif !important;
  font-weight: bold;
  font-size: 14px !important;
}
.copyright {
  font-family: "Lato", sans-serif !important;
  font-weight: 300;
  font-size: 12px !important;
}
input {
  font-family: "Lato", sans-serif !important;
  font-weight: 300;
  font-size: 14px;
}
.button,
.button2,
header a {
  font-family: "Lato", sans-serif !important;
  font-weight: bold;
  font-size: 14px !important;
}
.bold {
  font-weight: bold;
}

@media screen and (max-width: 1023px) {
  h1 {
    font-size: 36px !important;
  }
  h2 {
    font-size: 36px !important;
  }
  p {
    font-size: 14px !important;
  }
  li {
    font-size: 18px;
  }
  .h2-small,
  .h3-large {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  h1 {
    font-size: 48px !important;
  }
  p {
    font-size: 16px !important;
  }
}
