header .row {
  width: 100%;
}

.row {
  margin-left: 5% !important;
  margin-right: 5% !important;
  max-width: none !important;
  .row {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
}
.mobile-menu .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
section {
  position: relative;
}

// header START

header {
  z-index: 99;
}

$sticky-shrinknav-menu-height: rem-calc(60);
$sticky-shrinknav-hero-height: 150px;

body {
  padding-top: $sticky-shrinknav-hero-height + 30px;
}

.sticky-shrinknav-menu,
.sticky-shrinknav-menu2 {
  @include horizontal-center;
  bottom: 0;
  height: $sticky-shrinknav-menu-height;
  line-height: $sticky-shrinknav-menu-height;
  width: 100%;
  transition: all 0.5s ease;

  li {
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  a {
    color: $white;
    &:hover {
      color: $orange;
    }
  }
  .active {
    padding-bottom: 12px !important;
    border-bottom: 3px solid $purple !important;
  }
}
.sticky-shrinknav-header2 {
  background-color: $white;
}
.sticky-shrinknav-menu2 {
  a {
    color: $purple;
  }
}

.sticky-shrinknav-header-title {
  transition: all 0.3s ease;
  position: relative;
  transform: translateY(-$sticky-shrinknav-menu-height/2);
  margin-bottom: 0;
  color: $white;
}

.sticky-shrinknav-header,
.sticky-shrinknav-header2 {
  width: 100%;
  height: $sticky-shrinknav-hero-height;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.75s ease;
}

body.sticky-shrinknav-wrapper {
  .sticky-shrinknav-header,
  .sticky-shrinknav-header2 {
    height: $sticky-shrinknav-menu-height;
    padding: 50px 0px;
    box-shadow: 0 0 30px 0 rgba(3, 7, 42, 0.9);
  }
  .sticky-shrinknav-header {
    background-color: rgba($dark-blue, 1);
  }
  .sticky-shrinknav-header2 {
    background-color: $white;
  }
}

.sticky-shrinknav-menu .button,
.sticky-shrinknav-menu2 .button,
.sticky-shrinknav-menu .button2,
.sticky-shrinknav-menu2 .button2 {
  padding: 15px 25px 15px 25px !important;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  margin-left: 40px;
  color: $white;
}

#hamburger {
  text-align: right;
  padding-right: 0;
  cursor: pointer;
}
#menu-x {
  position: absolute;
  top: 20px;
  right: 15px;
}
#menu-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.hidden {
  display: none;
}
.mobile-menu {
  position: fixed;
  width: calc(100vw - 60px);
  margin: 40px 30px;
  padding: 30px 20px;
  background-color: $white;
}
.mobile-nav {
  list-style-type: none;
  text-align: left;
  margin: 50px 5px 50px 5px;
  li {
    margin-bottom: 25px;
  }
  a {
    color: $purple;
  }
  .active {
    padding-bottom: 8px !important;
    border-bottom: 3px solid $purple !important;
  }
}
.header-logo {
  float: left;
  max-height: 75px;
}

// header END

// footer START

footer {
  padding: 25px 0;
  background-color: $light-orange;

  .copyright {
    font-weight: 400;
    color: $light-gray;
    margin-left: 0;
  }
  a {
    color: $white;
    margin-left: 25px;
    &:hover {
      color: $purple;
    }
  }
}

@media screen and (max-width: 639px) {
  .footer-menu {
    padding-left: 0;
    margin-bottom: 30px;
  }
  footer {
    a {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}
@media screen and (min-width: 640px) {
  .footer-menu {
    text-align: right;
  }
}

// footer END

.button {
  background-color: $orange !important;
  &:hover {
    background-color: $white !important;
    color: $orange !important;
  }
}
.button2 {
  background-color: $white !important;
  color: $orange !important;
  &:hover {
    background-color: $orange !important;
    color: $white !important;
  }
}
.button,
.button2 {
  width: 100% !important;
  border-radius: 5px !important;
  padding: 20px 25px !important;
  border: solid 2px $orange !important;
  display: inline-block;
  text-align: center;
}
.blue-container {
  background-color: $blue;
}
.dark-blue-container {
  background-color: $dark-blue;
}
.purple-container {
  background-color: $purple;
  color: $white;
}
.padded-container {
  padding: 15% 0;
}
.padded-container-sm {
  padding: 5% 0;
}
.no-left-margin {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.no-right-margin {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.home-hero {
  position: relative;
  background-image: url("../img/home-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.hero {
  position: relative;
  background-size: cover;
}
.hero-title {
  //margin-top: 5%;
  //max-width: 500px !important;
  color: $white;
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.white-ang-t-u-r {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 10vw;
  bottom: calc(100% - 1px);
  background: white;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}
.demo-overlay,
.demo-overlay2 {
  padding: 35px 50px 25px 50px;
  border-radius: 10px;
  box-shadow: 0 10px 34px 0 rgba(3, 7, 42, 0.25);
  background-color: $white;
  img {
    width: 150px;
    margin-bottom: 35px;
  }
  p {
    margin-bottom: 35px;
  }
}
.demo-overlay,
.demo-overlay2,
.contact-form {
  input,
  textarea {
    opacity: 0.5;
    border-radius: 3px;
    border: solid 1px #a5a5a5;
    background-color: #c4c4c4;
    padding: 20px 20px;
    margin-bottom: 20px;
    color: $black !important;
    &::placeholder {
      color: $black;
    }
  }
  input {
    padding: 0px 20px;
    height: 60px;
  }
}
.demo-overlay2 {
  margin-top: -15%;
  margin-bottom: 10%;
}

@media screen and (max-width: 639px) {
  .home-hero-info {
    .row {
      margin-top: 8rem;
    }
  }
  .home-hero {
    background-size: 200% 90%;
  }
  .hero-title {
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .demo-overlay {
    margin-top: 5%;
  }
  .demo-overlay,
  .demo-overlay2 {
    padding: 35px 25px 15px 25px;
    img {
      max-width: 100px;
    }
  }
}
@media screen and (min-width: 640px) and (max-width: 1199px) {
  .home-hero-info {
    .row {
      margin-top: 10rem;
    }
  }
  .hero-title {
    margin-bottom: 5%;
  }
}
@media screen and (min-width: 1200px) {
  .home-hero-info {
    .demo-overlay {
      margin-left: 10%;
    }
    .row {
      margin-top: 15rem;
    }
  }
  .home-hero-info {
    padding-bottom: 15%;
  }
}

.four-column-bullets {
  h3 {
    padding: 50px 0;
    color: $white;
    text-align: center;

    &::before {
      content: url(../img/bullet.png);
      padding-right: 25px;
      vertical-align: -10%;
    }
  }
  .title-cell {
    color: $light-purple;
    text-align: right;
    padding-right: 50px;
    &::before {
      content: none;
    }
  }
}
@media screen and (max-width: 639px) {
  .four-column-bullets {
    h3 {
      padding: 50px 50px;
      text-align: left;
      padding-left: 4em;
      text-indent: -2.2em;
    }
    .title-cell {
      padding-left: 3.5em !important;
      text-indent: -2.4em;
    }
  }
}
@media screen and (min-width: 640px) {
  .four-column-bullets {
    h3 {
      padding-left: 0;
      text-indent: -0.5rem;
    }
    .title-cell {
      padding-left: 3.5em !important;
      text-indent: -2.4em;
    }
  }
}
@media screen and (max-width: 1023px) {
  .title-cell {
    text-align: left !important;
    padding-left: 50px !important;
  }
}
.map-overlay {
  background-image: url("../img/map.png");
  background-position: center;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  #map {
    z-index: -1;
    position: absolute;
    top: -75px;
    left: 0;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .map-overlay {
    background-size: unset;
  }
}
.solution-info {
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 10% !important;
  color: $white;
  h2,
  h3 {
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 50px;
  }
  h3 {
    &::before {
      content: url(../img/bullet.png);
      padding-right: 25px;
      vertical-align: -10%;
    }
  }
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  li {
    list-style-type: none;
    list-style-position: inside;
    text-indent: -2em;
    padding-left: 2em;
    padding-top: 20px;
    font-size: 24px !important;
    font-weight: 400;
    line-height: 1.5;
    &::before {
      content: url(../img/bullet.png);
      padding-right: 25px;
      vertical-align: -20%;
    }
  }
}

.blockchain-bg {
  .solution-info {
    padding-top: 12%;
    padding-bottom: 12%;

    li {
      text-indent: -2.2em;
    }
  }
}
.blockchain-bg2 {
  background-image: url("../img/blockchain.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
.our-values {
  padding: 8% 0 15% 0;
  h2 {
    color: $purple;
    margin-bottom: 25px;
  }
  h3 {
    color: $light-blue;
    font-weight: 900 !important;
    &:first-child {
      margin-top: 3%;
      margin-bottom: 48%;
    }
  }
  p {
    margin-bottom: 40px;
  }
}
.our-values-mobile {
  padding: 15% 0;
  h2 {
    color: $purple;
    margin-bottom: 25px;
  }
  h3 {
    color: $light-blue;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 40px;
  }
  img {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
.lsl-home {
  position: relative;
  padding: 14% 0 0 0;
  h2 {
    color: $purple;
    margin-bottom: 25px;
  }
  p {
    font-weight: 400;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 639px) {
  .our-values-mobile {
    text-align: center;
    p {
      font-weight: 400;
      line-height: 28px !important;
    }
  }
  .blockchain-bg {
    .solution-info {
      padding-top: 12%;
      padding-bottom: 20%;
    }
  }
}
@media screen and (max-width: 1023px) {
  .lsl-home-info {
    margin-top: 25px;
  }
  .lsl-devices {
    width: 100%;
    max-width: 550px;
    display: block;
    margin: 0 auto;
  }
}
@media screen and (min-width: 640px) and (max-width: 1023px) {
  .blockchain-bg {
    .solution-info {
      padding-top: 10%;
      padding-bottom: 20%;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .lsl-home {
    padding-top: 11%;
  }
}
@media screen and (min-width: 1024px) {
  .blockchain-bg {
    background-image: url("../img/blockchain.png");
    background-repeat: no-repeat;
    background-size: 50% 100%;
  }
  .stock-bg {
    background-image: url("../img/stock1.png");
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 45% 100%;
    height: 50vw;
  }
  .lsl-devices {
    position: absolute;
    width: 50%;
    bottom: -6vw;
    right: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .stock-bg {
    height: 45vw;
  }
}
@media screen and (min-width: 1510px) {
  .stock-bg {
    height: 40vw;
  }
  .lsl-devices {
    bottom: -6vw;
    right: 125px;
  }
}
.offerings {
  position: relative;
  h2 {
    color: $purple;
  }
  .yellow-hex {
    position: absolute;
    top: 100px;
    right: -200px;
  }
  .yellow-hex-outline {
    position: absolute;
    top: 200px;
    left: -100px;
  }
  .purple-hex {
    position: absolute;
    bottom: 150px;
    right: -325px;
  }
}
.offer-tile {
  border-radius: 10px;
  box-shadow: 0 10px 15px 0 rgba(3, 7, 42, 0.1);
  padding: 75px 45px !important;
  img {
    margin-bottom: 60px;
  }
  h3 {
    font-size: 30px;
    color: $purple;
    margin-bottom: 25px;
  }
}
.offers-group {
  margin: 5% 0 10% 0;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 1023px) {
  .right-border {
    h2 {
      margin-bottom: 25px;
    }
  }
  .offer-tile {
    padding: 35px 35px !important;
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .offer-tile {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .right-border {
    h2 {
      border-right: 2px solid $light-purple;
      margin-bottom: 0;
      margin-right: 20px;
      padding-right: 20px !important;
    }
  }
}
@media screen and (min-width: 1300px) {
  .right-border {
    h2 {
      margin-right: 50px;
      padding-right: 10px !important;
    }
  }
}

// contact START

.hero {
  margin-top: 10rem;
}
.contact-hero {
  background-image: url("../img/contact-hero.png");
  color: $white;
  padding: 135px 10px 75px 10px;
}
.contact-form {
  padding: 50px 50px;
  border-radius: 10px;
  box-shadow: 0 10px 34px 0 rgba(3, 7, 42, 0.25);
  background-color: $white;
  img {
    width: 150px;
    margin-bottom: 35px;
  }
  p {
    margin-bottom: 35px;
  }
}
.company-info {
  margin-top: 50px;
  margin-left: 10px;
  list-style-type: none;
  li {
    margin-bottom: 25px;
    padding-left: 50px;
    background-size: 32px 32px !important;
    line-height: 32px;
  }
  img {
    display: inline-block;
    margin-right: 10px;
  }
  p {
    display: inline-block;
  }
}
.contact-column {
  position: relative;
  .purple-hex-outline {
    position: absolute;
    top: 150px;
    left: -250px;
  }
  .yellow-hex-outline {
    position: absolute;
    bottom: 150px;
    right: -315px;
  }
}
.bad-input {
  box-shadow: 0 0 8px #cc0000 !important;
  border: 3px solid red !important;
  border-radius: 3px !important;
}

@media screen and (max-width: 639px) {
  .contact-form {
    padding: 30px 25px;
  }
}
@media screen and (min-width: 640px) {
  .demo-overlay,
  .demo-overlay2,
  .contact-form {
    .input-half {
      width: 48%;
      float: left;
    }
    .input-half-right {
      margin-left: 4%;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .contact-form {
    padding: 35px 30px;
  }
}
@media screen and (min-width: 1024px) {
  .contact-form {
    margin-right: 25px;
  }
}

// contact END

// coming soon  and 404 START

.coming-soon,
.lost {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
  margin-top: 10rem;
  padding: 100px 25px;
  h1 {
    color: $white;
    margin-bottom: 25px;
  }
  .h2-small {
    color: $white;
    margin-bottom: 50px;
  }
  .button,
  .button2 {
    max-width: 300px;
  }
}
.coming-soon {
  background-image: url("../img/blockchain.png");
}
.lost {
  background-image: url("../img/map.png");
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

// coming soon and 404 END

// platform START

.platform-hero {
  background-image: url("../img/platform-hero.png");
  color: $white;
  padding: 135px 10px 75px 10px;
}
.platform-info {
  position: relative;
  padding: 75px 0 75px 0;
  .platform-diagram {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 1050px;
  }
  .yellow-hex {
    position: absolute;
    top: 200px;
    right: -550px;
  }
  .purple-hex-outline {
    position: absolute;
    bottom: 300px;
    left: -250px;
  }
}
// platform END

// about us START

.about-hero {
  background-image: url("../img/story-hero.png");
  color: $white;
  text-align: center;
  padding: 100px 10px 100px 10px;
}
.about-info {
  position: relative;
  margin: -7% 0 20% 0;
  padding: 50px 40px 40px 40px;
  border-radius: 10px;
  box-shadow: 0 10px 34px 0 rgba(3, 7, 42, 0.25);
  background-color: $white;
  position: relative;
  z-index: 10;
  img {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purple-hex {
    position: absolute;
    top: 250px;
    left: -600px;
  }
  .yellow-hex-outline {
    position: absolute;
    bottom: -50px;
    right: -285px;
  }
}
@media screen and (max-width: 639px) {
  .about-info {
    margin-top: -15%;
    padding: 35px 25px 25px 25px;
  }
}
@media screen and (min-width: 640px) and (max-width: 1050px) {
  .about-info {
    margin-top: -10%;
    padding: 45px 35px 35px 35px;
  }
}

// about us END

// our team START

.team {
  margin: 225px 0 10% 0;

  h1,
  h2 {
    color: $purple;
  }
  h1 {
    margin-bottom: 50px;
  }
  .button,
  .button2 {
    margin-top: 25px;
  }
}
.team-member {
  position: relative;
  margin-bottom: 60px;
  padding: 50px 60px 40px 60px;
  border-radius: 10px;
  box-shadow: 0 10px 34px 0 rgba(3, 7, 42, 0.25);
  background-color: $white;

  .yellow-hex-outline {
    position: absolute;
    top: 35px;
    right: -300px;
  }
  .purple-hex-outline {
    position: absolute;
    top: 75px;
    left: -225px;
  }
  .yellow-hex {
    position: absolute;
    top: -130px;
    left: -575px;
  }
  .purple-hex {
    position: absolute;
    top: -175px;
    right: -500px;
  }
}
.team-member-pic {
  text-align: center;

  .social-icons {
    margin-top: 40px !important;
    a {
      margin: 0 10px 0 10px;
    }
  }
}
.team-member-info {
  p {
    font-weight: 400 !important;
  }
  h2 {
    margin-bottom: 15px;
  }
  h3 {
    color: $purple;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 639px) {
  .team {
    .button,
    .button2 {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 1023px) {
  .team {
    margin-top: 190px;
    .button,
    .button2 {
      margin-top: 0;
    }
  }
  .team-member {
    padding: 50px 40px 40px 40px;
  }
  .team-member-pic {
    margin-bottom: 50px;

    .social-icons {
      margin-top: 25px !important;
    }
  }
}
@media screen and (min-width: 640px) and (max-width: 1200px) {
  .team {
    .button,
    .button2 {
      margin-top: 0;
    }
  }
  .team-member {
    padding: 50px 50px 40px 50px;
  }
}
@media screen and (min-width: 1024px) {
  .team-member-info:nth-child(even) {
    padding-left: 50px;
  }
}

// our team END

// modal START

.reveal {
  padding: 50px 50px !important;
  border: 3px solid $orange !important;
  border-radius: 15px !important;
  position: relative;
  .modal-content {
    text-align: center;
    margin-top: 75px;
    width: 80%;
    margin-left: 10%;
  }
  img {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 15%;
    max-width: 100px;
    min-width: 50px;
  }
  h1 {
    color: $purple;
    margin-bottom: 25px;
  }
  p {
    font-weight: 400;
  }
  .button,
  .button2 {
    margin-top: 50px !important;
  }
}
.close-button {
  position: unset !important;
  color: $white !important;
  width: 200px !important;
  display: block !important;
  margin: 0 auto !important;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .reveal {
    p {
      font-size: 18px !important;
    }
  }
}
@media screen and (max-width: 639px) {
  .reveal {
    width: 90% !important;
    margin: 0% 5% !important;
    height: unset !important;
    min-height: unset !important;
    padding: 50px 15px !important;
    .modal-content {
      margin-top: 50px;
    }
  }
}

// modal END

// privacy policy START

.privacy-hero {
  background-image: url("../img/home-hero.png");
  color: $white;
  text-align: center;
  padding: 100px 10px 100px 10px;
}
.privacy-policy {
  position: relative;
  padding: 75px 0 75px 0;
  .platform-diagram {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 1050px;
  }
  ul {
    margin-left: 50px;
  }
  li {
    line-height: normal;
    margin-bottom: 8px;
    .bold {
      margin-right: 5px;
    }
  }
  ul,
  li > ul {
    margin-top: 8px;
  }
  li > ul > li {
    list-style: circle !important;
  }
  li > ul > li > ul > li {
    list-style: square !important;
  }
}

@media screen and (max-width: 1023px) {
  .privacy-policy {
    p a,
    li a,
    li {
      font-size: 14px !important;
    }
  }
}
@media screen and (min-width: 1024px) {
  .privacy-policy {
    p a,
    li a {
      font-size: 18px !important;
    }
  }
}

// privacy policy END


// claris ai START
.claris {
  border-bottom: 8px solid;
  border-image: linear-gradient(90deg, #076FB3 0%, #33AE9C 100%) 1;
  background: linear-gradient(180deg, #FFFFFF 0%, #E0EFF8 100%);
  color: #101828;
}
.claris-padded-container {
  padding: 6% 0;
}
.claris-bg-img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 500px;
    opacity: .2;
    transform: rotate(180deg);
    z-index: 1;
}
.claris-group {
  margin: 5% 0 5% 0;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
.claris-tile {
  padding: 0 5% 0 0;
  img {
    padding-bottom: 1rem;
  }
}
.claris-content {
  p {
      &:last-child {
      margin-bottom: 0 !important;
    }
  }
  h1 {
    font-size: 38px !important;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 26px !important;
    margin-bottom: 1.5rem;
  }
  .lg-mb {
    margin-bottom: 3.5rem;
  }
}
.claris-button-container {
  margin-top: 1rem;
  a {
    margin-right: .5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.claris-button {
  background-color: #33AE9C !important;
  color: $white;
  &:hover {
    background-color: $white !important;
    color: #33AE9C !important;
  }
}
.claris-button2 {
  background-color: $white !important;
  color: #344054 !important;
  &:hover {
    background-color: #344054 !important;
    color: $white !important;
  }
}
.claris-button, .claris-button2 {
  position: relative;
  z-index: 10;
  padding: .75rem 1.5rem;
  border-radius: .5rem;
}
@media screen and (min-width: 640px) and (max-width: 1199px) {
  .claris {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .claris-group .column {
    &:last-child {
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: 639px) {
  .claris {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .claris-group .column {
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .claris-button-container {
    a {
      display: block;
      max-width: fit-content;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
//claris ai END
